.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

body {
  height: 100% !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.transition-3 {
  transition: all 0.33s ease;
  -webkit-transition: all 0.33s ease;
  -moz-transition: all 0.33s ease;
}

.transition-5 {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
}

/* react-chatbot-kit */
/* https://fredrikoseberg.github.io/react-chatbot-kit-docs/docs/advanced/configuring-css */
.react-chatbot-kit-chat-message-container {
  border: 1 px solid red;
}

.react-chatbot-kit-chat-input {
  border: 1 px solid red;
}

/* Base styles for pagination links */
.pagination-link {
  display: inline-block;
  padding: 5px 10px;
  margin: 2px;
  text-decoration: none;
  color: white;
}

.li.pagination.previous {
  color: #00d1b2 !important;
}

/* Styles for the current pagination link */
li.pagination-link.is-current {
  border: 1px solid #00d1b2 !important;
  background-color: #00d1b2 !important;
  color: #fff;
  border-color: #00d1b2 !important;
}

a.pagination-link {
  text-decoration: none !important;
}

.pagination-link .is-current {
  text-decoration: none !important;
  border: 1px solid #00d1b2 !important;
  border-color: #00d1b2 !important;
  color: white !important;
}

li.a.pagination-link.is-current {
  text-decoration: none;
  border: 1px solid #00d1b2 !important;
  border-color: #00d1b2 !important;
  color: white !important;
}

li.pagination-link.is-current.a {
  text-decoration: none;
  border: 1px solid #00d1b2 !important;
  border-color: #00d1b2 !important;
  color: white !important;
}

.pagination-link.is-current {
  text-decoration: none;
  border: 1px solid #00d1b2 !important;
  background-color: #00d1b2 !important;
  color: #fff;
  border-color: #00d1b2 !important;
}

a.pagination-link.is-current {
  background-color: #00d1b2;
  border-color: #00d1b2;
  color: white;
}

.pagination-list {
  display: flex;
  justify-content: center;
  align-content: center;
  text-decoration: none;
  border-color: #00d1b2 !important;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

ul.pagination-list {
  display: flex;
  justify-content: center;
  align-content: center;
  text-decoration: none;
  border-color: #00d1b2 !important;
  margin: 0 auto;
}

ul.pagination-list li {
  display: block;
}

/* Chat styling */
html,
body {
  overflow: hidden;
}

*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  border-radius: 23111998px;
}

*::-webkit-scrollbar-thumb {
  background-color: #ccc;
  outline: 1px solid #bbb;
  border-radius: 23111998px;
}

.sidebar-user {
  display: block;
  border-right: 1px solid #dedede;
}

.sidebar-user .top-field {
  padding: 25px 20px 5px 25px;
}

.sidebar-user .top-field .input {
  background-color: #f9f9f9;
  border-radius: 23111998px;
}

.sidebar-user .top-field .input::placeholder {
  color: #ccc;
}

.sidebar-user .list {
  margin-left: 25px;
  border: none !important;
  box-shadow: none;
}

@media screen and (max-width: 768px) {
  .sidebar-user .list {
    margin-top: 5px;
  }
}

.sidebar-user .list .box {
  margin-bottom: 0;
  margin-right: 15px;
  box-shadow: none;
  border-radius: none;
  background-color: #fff;
  border: none !important;
}

.sidebar-user .list .box.active {
  background-color: #dedede !important;
}

.sidebar-user .list a.box:hover,
.sidebar-user .list a.box:focus,
.sidebar-user .list a.box:active {
  box-shadow: none;
  background-color: #f9f9f9;
}

.sidebar-user .list {
  max-height: calc(100vh - 120px);
  overflow-y: auto;
  overflow-x: hidden;
}

.user-nav {
  padding-top: 20px;
  padding-bottom: 8px;
  padding-left: 12px;
  padding-right: 20px;
  z-index: 29;
  display: flex;
  align-items: stretch;
}

.user-nav .navbar-start {
  justify-content: flex-start;
  margin-right: auto;
}

.user-nav .navbar-end {
  justify-content: flex-end;
  margin-left: auto;
}

.user-nav .navbar-start,
.user-nav .navbar-end {
  align-items: stretch;
  display: flex;
}

.user-nav .navbar-item:hover {
  color: currentColor;
}

.message-window {
  min-height: calc(100vh - 76px) !important;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}

.flex-item-1 {
  flex-grow: 1;
}

.flex-item-2 {
  width: 100%;
  height: 80px;
  padding: 10px 12px;
}

.flex-item-2 .field.has-addons .control:first-child {
  width: 100%;
}

.flex-item-2 .input {
  border-radius: 23111998px !important;
  background-color: #f9f9f9;
}

.flex-item-2 .button {
  padding: 0 5px;
  border: none;
}

@media screen and (max-width: 690px) {
  .is-32x32-mobile {
    height: 32px !important;
    width: 32px !important;
    margin-left: -8px;
  }
}

.sidebar-profile {
  border-left: 1px solid #dedede;
}

.horizontal-scroll {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
}

.scrollable-item {
  width: 100vw;
}
